<template>
  <div style="min-height: 200px">
    <div class="table-responsive">
      <table id="trial" class="table table-bordered">
        <thead>
        <tr>
          <th>Date</th>
          <th>Adjustment No</th>
          <th>Item Name</th>
          <th>Cost Center</th>
          <th>Project</th>
          <th>Warehouse</th>
          <th>In Qty</th>
          <th>Out Qty</th>
          <th>In Amount</th>
          <th>Out Amount</th>
          <th>Description</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(item, i) in tableItems">
          <tr v-for="(stock, j) in item.stocks" :key="j">
            <td>{{ item.date }}</td>
            <td>{{ item.adjustment_no }}</td>
            <td>{{ stock.product.name }}</td>
            <td>{{ item.cost_centre_name }}</td>
            <td>{{ item.project_name }}</td>
            <td>{{ item.warehouse_name }}</td>
            <td>{{ stock.quantity_in }}</td>
            <td>{{ stock.quantity_out }}</td>
            <td>{{ stock.amount_in }}</td>
            <td>{{ stock.amount_out }}</td>
            <td>{{ item.description }}</td>
          </tr>
        </template>
        </tbody>
        <tbody>
        <tr>
          <th colspan="6" class="text-center">Total</th>
          <th>{{ getSum('quantity_in') }}</th>
          <th>{{ getSum('quantity_out') }}</th>
          <th>{{ getSum('amount_in') }}</th>
          <th>{{ getSum('amount_out') }}</th>
          <th></th>
        </tr>
        </tbody>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from '@/services/utils/figureFormatter'

const {commaFormat} = figureFormatter()

const props = defineProps({
  tableItems: {
    type: Array,
  }
})

const getSum = (columnName) => {
  if (!Array.isArray(props.tableItems) || !props.tableItems.length) {
    return 0;
  }

  const total = props.tableItems.reduce((total, item) => {
    return total + item.stocks.reduce((subtotal, stock) => {
      return subtotal + parseFloat(stock[columnName]);
    }, 0);
  }, 0);

  return commaFormat(total);
};
</script>
